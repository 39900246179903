import { ref, computed, watch } from '@vue/composition-api'
// import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { debounce } from 'lodash'


export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()
  const { t } = useI18nUtils();

  store.dispatch('schedule_task/getAll')
  const scheduleTaskOptions = computed(() => store.getters['schedule_task/getSelectedItems'])

  store.dispatch('command/getAll', { alarm_compatible: true })
  const commandOptions = computed(() => store.getters['command/getSelectedItems'].map(c => {
    return {
      value: c.value,
      label: c.cmd === 'custom' ? c.label : t(`commands.list.${c.label}.name`),
      cmd: c.cmd
    }
  }))

  store.dispatch('cm_camera/getAll')
  const cameraOptions = computed(() => store.getters['cm_camera/getSelectedItems'])

  store.dispatch('frequency_schedule_task/getAll')
  const frequencyScheduleTaskOptions = computed(() => store.getters['frequency_schedule_task/getSelectedItems'].map(st => ({ value: st.value, label: t(`scheduleOptions.${st.label}.name`), code: st.code })))

  store.dispatch('grid/getUserViews');
  const userViewsOptions = computed(() => store.getters['grid/getUserViews'].map(userv => ({ label: userv.name, value: userv.id })));

  store.dispatch('unit/getUnitsByTenant');
  const unitsOptions = computed(() => store.getters['unit/getSelectedItems']);
  const unitsLoadingStatus = computed(() => store.getters['unit/getUnitsLoadingStatus']);

  /*if (router.currentRoute.params.description)
    buttonFilter.value = parseInt(router.currentRoute.description)*/

  const tableColumns = [
    {
      label: 'command.title', key: 'command', sortable: true,
      formatter: (value, key, item) => {
        return value.command === 'custom' ? value.name : t(`commands.list.${value.name}.name`);
      }
    },
    {
      label: 'schedule_task.frequency', key: 'frequency', sortable: true,
      formatter: (value, key, item) => {
        return value.name;
      }
    },
    {
      label: 'workflow.params', key: 'config', sortable: true, formatter: (value, key, item) => {
        if (value) return value.join(', ');
      }
    },
  ]

  if (ability.can('update', 'ScheduleTask') == true || ability.can('delete', 'ScheduleTask') == true) {
    tableColumns.splice(0, 0, { label: 'actions.name', key: 'actions' })

  }

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })


  const fetchList = (ctx, callback) => {
    store.dispatch('schedule_task/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      //type: buttonFilter.value,
    })
      .then(response => {
        callback(response.data)
        totalList.value = response.meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([ currentPage, perPage ], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    scheduleTaskOptions,
    commandOptions,
    cameraOptions,
    frequencyScheduleTaskOptions,
    userViewsOptions,
    unitsOptions,
    unitsLoadingStatus,
    refetchData,
  }
}
