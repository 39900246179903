<template>
  <div class="mt-3">
    <h4 class="mb-1">{{ $t("cronEditor.title") }}</h4>
    <select
      v-model="form.type"
      @change="currentItem = null"
      class="form-control custom-select mb-2"
    >
      <option value="1">{{ $t("cronEditor.options.op1") }}</option>
      <option value="2">{{ $t("cronEditor.options.op2") }}</option>
      <option value="3">{{ $t("cronEditor.options.op3") }}</option>
      <option value="4">{{ $t("cronEditor.options.op4") }}</option>
    </select>

    <b-form @submit.prevent="onSubmitForm" class="mb-1">
      <!-- Hourly -->
      <b-row v-if="form.type == 1" class="mb-1">
        <b-col cols="6">
          <span class="d-block">{{ $t("cronEditor.every") }}</span>
          <b-form-input
            class="mb-25 w-100"
            v-model="form.every"
            placeholder="Hora(s)"
            type="number"
            min="1"
            size="sm"
          ></b-form-input>
          <span class="d-block">{{ $t("cronEditor.hours") }}</span>
        </b-col>
        <b-col cols="6">
          <span class="d-block">{{ $t("cronEditor.on_minute") }}</span>
          <b-form-input
            class="mb-25 w-100"
            v-model="form.time"
            placeholder="Minuto(s)"
            type="number"
            min="1"
            size="sm"
          ></b-form-input>
        </b-col>
      </b-row>

      <!-- Daily -->
      <b-row v-if="form.type == 2" class="mb-1">
        <b-col cols="6">
          <span class="d-block">{{ $t("cronEditor.every") }}</span>
          <b-form-input
            class="mb-25 w-100"
            v-model="form.every"
            :placeholder="$t('cronEditor.days')"
            type="number"
            min="1"
            size="sm"
            ref="every"
          ></b-form-input>
          <span class="d-block">{{ $t("cronEditor.days") }}</span>
        </b-col>
        <b-col cols="6">
          <span class="d-block">{{ $t("cronEditor.at") }}</span>
          <b-form-input
            class="mb-25 w-100"
            v-model="form.time"
            :placeholder="$t('cronEditor.minutes')"
            type="time"
            min="1"
            size="sm"
            ref="time"
          ></b-form-input>
        </b-col>
      </b-row>

      <!-- Weekly -->
      <b-row v-if="form.type == 3" class="mb-1">
        <b-col cols="6">
          <b-form-checkbox-group
            v-model="form.selectedDays"
            :options="daysOptions"
            name="days"
            class="d-flex flex-column"
          >
          </b-form-checkbox-group>
        </b-col>
        <b-col cols="6">
          <span class="d-block">{{ $t("cronEditor.at") }}</span>
          <b-form-input
            class="mb-25 w-100"
            v-model="form.time"
            :placeholder="$t('cronEditor.minutes')"
            type="time"
            min="1"
            size="sm"
            ref="time"
          ></b-form-input>
        </b-col>
      </b-row>

      <!-- Monthly -->
      <b-row v-if="form.type == 4" class="mb-1">
        <b-col cols="4">
          <span class="d-block">{{ $t("cronEditor.on_the") }}</span>
          <b-form-input
            class="mb-25 w-100"
            v-model="form.day"
            :placeholder="$t('cronEditor.day')"
            type="number"
            min="1"
            size="sm"
            ref="day"
          ></b-form-input>
          <span class="d-block">{{ $t("cronEditor.day_of_every_month") }}</span>
        </b-col>
        <b-col cols="4">
          <span class="d-block">{{ $t("cronEditor.every") }}</span>
          <b-form-input
            class="mb-25 w-100"
            v-model="form.every"
            :placeholder="$t('cronEditor.months')"
            type="number"
            min="1"
            size="sm"
            ref="every"
          ></b-form-input>
          <span class="d-block">{{ $t("cronEditor.months") }}</span>
        </b-col>
        <b-col cols="4">
          <span class="d-block">{{ $t("cronEditor.at") }}</span>
          <b-form-input
            class="mb-25 w-100"
            v-model="form.time"
            :placeholder="$t('cronEditor.minutes')"
            type="time"
            min="1"
            size="sm"
            ref="time"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row v-if="errors.show">
        <b-col cols="12">
          <p class="errors text-danger">{{ errors.text }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button type="submit" variant="primary" size="sm" v-if="currentItem !== null">
            {{ $t("cronEditor.btn_update") }}
          </b-button>
          <b-button type="submit" variant="primary" size="sm" v-else>
            {{ $t("cronEditor.btn_add") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <table class="table table-sm table-bordered" v-if="customFrequency.length">
      <tr>
        <th style="width: 300px">{{ $t("cronEditor.table.desc") }}</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in customFrequency" :key="index">
        <td>{{ parseConExp(item.cronExp) }}</td>
        <td>
          <a href="#" @click.prevent="onEditClick(index)" class="mr-25">
            <feather-icon icon="EditIcon" size="14" />
          </a>
          |
          <a href="#" @click.prevent="onDeleteClick(index)" class="ml-25">
            <feather-icon icon="Trash2Icon" size="14" />
          </a>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckboxGroup,
  BRow,
  BCol,
} from "bootstrap-vue";
import vSelect from "vue-select";
import cronTime from "cron-time-generator";
import cronstrue from "cronstrue";

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BFormCheckboxGroup,
    BRow,
    BCol,
  },
  props: {
    customFrequency: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      daysOptions: [
        { text: this.$t("cronEditor.weekdays.sun"), value: "1:sunday" },
        { text: this.$t("cronEditor.weekdays.mon"), value: "2:monday" },
        { text: this.$t("cronEditor.weekdays.tue"), value: "3:tuesday" },
        { text: this.$t("cronEditor.weekdays.wed"), value: "4:wednesday" },
        { text: this.$t("cronEditor.weekdays.thu"), value: "5:thursday" },
        { text: this.$t("cronEditor.weekdays.fri"), value: "6:friday" },
        { text: this.$t("cronEditor.weekdays.sat"), value: "7:saturday" },
      ],
      form: {
        type: 1,
        day: "",
        every: "",
        time: "",
        selectedDays: [],
        cronExp: "",
      },
      currentItem: null,
      rules: {
        1: { rules: ["every", "time"] },
        2: { rules: ["every", "time"] },
        3: { rules: ["selectedDays", "time"] },
        4: { rules: ["day", "every", "time"] },
      },
      errors: {
        show: false,
        text: "",
      },
    };
  },
  methods: {
    calcHourly() {
      const every = this.form.every;
      const minutes = this.form.time;

      return `${minutes} */${every} * * *`;
    },
    calcDaily() {
      const every = parseInt(this.form.every);
      const time = this.form.time;
      const parsedTime = time.split(":").map((t) => parseInt(t));

      return cronTime.every(every).days(parsedTime[0], parsedTime[1]);
    },
    calcWeekly() {
      const days = this.form.selectedDays
        .sort((a, z) => a > z)
        .map((sd) => sd.split(":")[1]);
      const time = this.form.time;
      const parsedTime = time.split(":").map((t) => parseInt(t));

      return cronTime.onSpecificDaysAt(days, parsedTime[0], parsedTime[1]);
    },
    calcMonthly() {
      const day = parseInt(this.form.day);
      const every = parseInt(this.form.every);
      const time = this.form.time;
      const parsedTime = time.split(":").map((t) => parseInt(t));

      return `${parsedTime[1]} ${parsedTime[0]} ${day} */${every} *`;
    },
    parseConExp(cronExp) {
      return cronstrue.toString(cronExp, {
        locale: this.$i18n.locale,
        use24HourTimeFormat: true,
      });
    },
    onEditClick(index) {
      this.currentItem = index;
      this.form = Object.assign({}, this.customFrequency[index]);
    },
    onDeleteClick(index) {
      this.currentItem = index;
      this.customFrequency.splice(index, 1);
      this.currentItem = null;
      this.$emit("update:custom-frequency", this.customFrequency);
    },
    validate(type) {
      const rules = this.rules[type].rules;

      for (let index = 0; index < rules.length; index++) {
        const field = rules[index];
        if (this.form[field] === null) return false;
        if (this.form[field].length === 0) return false;
      }

      return true;
    },
    checkIfDuplicated() {
      const exp = this.customFrequency.filter(
        (data) => data.cronExp === this.form.cronExp
      );

      if (exp.length > 0) {
        this.errors = {
          show: true,
          text: $t("cronEditor.errors.err1"),
        };

        return true;
      }

      return false;
    },
    reset() {
      this.form = {
        type: 1,
        day: "",
        every: "",
        time: "",
        selectedDays: [],
        cronExp: "",
      };
    },
    onSubmitForm() {
      if (!this.validate(this.form.type)) {
        this.errors = {
          show: true,
          text: $t("cronEditor.errors.err2"),
        };
        return;
      }

      this.errors = { show: false, text: "" };

      if (this.form.type == 1) {
        const result = this.calcHourly();
        this.form.cronExp = result;
      }

      if (this.form.type == 2) {
        const result = this.calcDaily();
        this.form.cronExp = result;
      }

      if (this.form.type == 3) {
        const result = this.calcWeekly();
        this.form.cronExp = result;
      }

      if (this.form.type == 4) {
        const result = this.calcMonthly();
        this.form.cronExp = result;
      }

      if (this.checkIfDuplicated()) return;

      if (this.currentItem !== null) {
        this.customFrequency[this.currentItem] = Object.assign({}, this.form);
        this.currentItem = null;
      } else {
        this.customFrequency.push(Object.assign({}, this.form));
      }

      this.$emit("update:custom-frequency", this.customFrequency);
      this.reset();
    },
  },
};
</script>
