<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 text-capitalize">
          {{
            isAdd
              ? $t("actions.add") + " " + $t("schedule_task.title")
              : $t("actions.edit") + " " + $t("schedule_task.title")
          }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Command -->
          <validation-provider
            #default="validationContext"
            name="command"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('command.title')"
              label-for="command"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_command"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="commandOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_command"
                :disabled="isDisabled"
                @option:selecting="onCommandSelected"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Camera -->
          <validation-provider
            #default="validationContext"
            name="camera"
            rules="required"
            v-if="command && command.cmd == 'push:camera'"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('Cameras')"
              label-for="camera"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_camera"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="cameraOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                :multiple="!isDisabled"
                input-id="id_camera"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Show View/Tab -->
          <validation-provider
            #default="validationContext"
            name="view"
            rules="required"
            v-if="command && command.cmd == 'active:router'"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('Units')"
              label-for="units"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-if="!unitsLoadingStatus"
                v-model="itemData.id_unit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="unitsOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_unit"
              />
              <p v-else>Loading units...</p>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Show View/Tab -->
          <validation-provider
            #default="validationContext"
            name="view"
            rules="required"
            v-if="command && command.cmd == 'view:show'"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('schedule_task.add_user_views')"
              label-for="view"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_user_view"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userViewsOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_view"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- view periocidad -->
          <validation-provider
            #default="validationContext"
            name="frequency"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('workflow.frequency')"
              label-for="frequency"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_frecuency_schedule_task"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="frequencyScheduleTaskOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_frecuency_schedule_task"
                @option:selecting="onFrequencySelected"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div>
            <cron-editor
              v-if="frequency === 'custom'"
              :custom-frequency.sync="customFrequency"
            />
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ isAdd ? $t("actions.add") : $t("actions.edit") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t("actions.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import CronEditor from "./CronEditor.vue";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    CronEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    scheduleTaskOptions: {
      type: Array,
      required: true,
    },
    commandOptions: {
      type: Array,
      required: true,
    },
    cameraOptions: {
      type: Array,
      required: true,
    },
    frequencyScheduleTaskOptions: {
      type: Array,
      required: true,
    },
    userViewsOptions: {
      type: Array,
      required: true,
    },
    unitsOptions: {
      type: Array,
      required: true,
    },
    unitsLoadingStatus: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const blankData = {};
    const toast = useToast();
    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };
    const customFrequency = ref([]);
    const user = JSON.parse(window.localStorage.userData);
    const extractParamValue = (str) =>
      str.indexOf("=") >= 0 ? str.substring(str.indexOf("=") + 1) : param;
    const command = ref(null);
    const frequency = ref(null);

    const onCommandSelected = (evt) =>
      (command.value = props.commandOptions.find((cmd) => cmd.value == evt.value));

    const onFrequencySelected = (evt) =>
      (frequency.value = props.frequencyScheduleTaskOptions.find(
        (freq) => freq.value === evt.value
      )?.code);

    const onSubmit = () => {
      let dispatch;

      let data = {
        id_command: itemData.value.id_command,
        id_frecuency_schedule_task: itemData.value.id_frecuency_schedule_task,
        config: [],
        multiple: false,
        custom_frequency: customFrequency.value,
      };

      command.value = props.commandOptions.find(
        (cmd) => cmd.value === itemData.value.id_command
      );

      switch (command.value.cmd) {
        // Add camera to monitor
        case "push:camera":
          if (props.isAdd) {
            data.multiple = true;
            itemData.value.id_camera.forEach((cameraId) =>
              data.config.push([`--userId=${user.id}`, `--cameraId=${cameraId}`])
            );
          } else {
            data.config.push(`--userId=${user.id}`);
            data.config.push(`--cameraId=${itemData.value.id_camera}`);
          }
          break;

        // Add view on monitor
        case "push:view":
          data.config.push(`--userId=${user.id}`);
          data.config.push(`--viewId=${itemData.value.id_view}`);
          break;

        // Enable speaker
        case "active:router":
          data.config.push(`--unitId=${itemData.value.id_unit}`);
          break;

        // Add view/tab on PSIM 2
        case "view:show":
          data.config.push(`--idUserView=${itemData.value.id_user_view}`);
          break;

        case "custom":
          data.config.push(`--idCommand=${itemData.value.id_command}`);
          break;
      }

      if (props.isAdd) {
        dispatch = store.dispatch("schedule_task/add", data);
      } else {
        const request = {
          id: itemData.value.id,
          params: data,
        };
        dispatch = store.dispatch("schedule_task/edit", request);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          customFrequency.value = [];
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;
        customFrequency.value = JSON.parse(
          JSON.stringify(itemData.value.custom_frequency)
        );

        command.value = props.commandOptions.find(
          (cmd) => cmd.value === itemData.value.id_command
        );

        frequency.value = props.frequencyScheduleTaskOptions.find(
          (freq) => freq.value === itemData.value.id_frecuency_schedule_task
        )?.code;

        if (command.value.cmd === "push:camera") {
          const param = itemData.value.config;
          itemData.value.id_camera = parseInt(extractParamValue(param[1]));
        }

        if (command.value.cmd === "push:view") {
          const param = itemData.value.config;
          itemData.value.id_view = parseInt(extractParamValue(param[0]));
        }

        if (command.value.cmd === "active:router") {
          const param = itemData.value.config;
          itemData.value.id_unit = parseInt(extractParamValue(param[0]));
        }

        if (command.value.cmd === "view:show") {
          let param = itemData.value.config;
          itemData.value.id_user_view = parseInt(extractParamValue(param[0]));
        }
      }
    };

    return {
      itemData,
      onSubmit,

      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
      customFrequency,
      command,
      onCommandSelected,
      frequency,
      onFrequencySelected,
    };
  },
};
</script>