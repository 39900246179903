<template>
  <div>
    <sidenav
      :is-sidebar-active.sync="isSidebarActive"
      :item-edit.sync="itemEdit"
      :is-add.sync="isAdd"
      @refetch-data="refetchData"
      :schedule-task-options="scheduleTaskOptions"
      :command-options="commandOptions"
      :camera-options="cameraOptions"
      :frequency-schedule-task-options="frequencyScheduleTaskOptions"
      :user-views-options="userViewsOptions"
      :units-options="unitsOptions"
      :units-loading-status="unitsLoadingStatus"
      :is-disabled="isDisabled"
    />
    <!-- Filters -->
    <!-- <filters
      :button-filter.sync="buttonFilter"
      :schedule-task-options="scheduleTaskOptions"
    />-->
    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search')"
              />

              <b-button
                variant="primary"
                @click="openAdd()"
                v-if="$can('create', 'Workflow')"
              >
                <span class="text-nowrap text-capitalize">{{
                    $t("actions.add") + " " + $t("schedule_task.title")
                  }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Loading data or records not found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>

        <template #head(id_buttons_types)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #cell(frequency)="data">
          <span v-if="data.item.frequency.value !== 'custom'">
            {{ $t(`scheduleOptions.${data.item.frequency.name}.name`) }}
          </span>
          <template v-else>
            <p
              class="mb-0"
              v-for="(cf, index) in data.item.custom_frequency"
              :key="index"
            >
              {{ parseConExp(cf.cronExp) }}
            </p>
          </template>
        </template>

        <template #cell(config)="data">
          <pre>{{ JSON.stringify(data.item.config, null, "\t") }}</pre>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="openEdit(data.item)"
              v-if="$can('update', 'ScheduleTask')"
            >
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50 text-capitalize">{{
                  $t("actions.edit")
                }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="confirmDelete(data.item.id)"
              v-if="$can('delete', 'ScheduleTask')"
            >
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50 text-capitalize">{{
                  $t("actions.delete")
                }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
                $t("table.showing", {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of,
                })
              }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  // BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {ref, getCurrentInstance, computed} from "@vue/composition-api";
import useList from "./useList";
import Sidenav from "./Sidenav.vue";
import Filters from "./Filters.vue";
import {useToast} from "vue-toastification/composition";
import ToastificationContent
  from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import cronstrue from "cronstrue";
import "cronstrue/locales/es";
import "cronstrue/locales/en";
import Pagination from "@/components/Pagination.vue";


export default {
  components: {
    Sidenav,
    Filters,
    Pagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    const instance = getCurrentInstance();
    const currentLocale = computed(() => instance.proxy.$i18n.locale);
    const isSidebarActive = ref(false);
    const itemEdit = ref({});
    const isAdd = ref(false);
    const isDisabled = ref(false);

    const openEdit = (item) => {
      isSidebarActive.value = true;
      itemEdit.value = item;
      isAdd.value = false;
      isDisabled.value = true;
    };
    const {t} = useI18nUtils();

    const toast = useToast();

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      scheduleTaskOptions,
      commandOptions,
      cameraOptions,
      frequencyScheduleTaskOptions,
      userViewsOptions,
      unitsOptions,
      unitsLoadingStatus,
    } = useList();

    const confirmDelete = function (id) {
      const self = this;
      const storeInner = store;
      this.$swal({
        title: context.parent.$i18n.t("messages.delete.title"),
        text: context.parent.$i18n.t("messages.delete.body"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: context.parent.$i18n.t("actions.delete"),
        cancelButtonText: context.parent.$i18n.t("actions.cancel"),
        customClass: {
          confirmButton: "btn btn-primary text-capitalize",
          cancelButton: "btn btn-outline-danger ml-1 text-capitalize",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          storeInner
            .dispatch("schedule_task/delete", id)
            .then((response) => {
              if (response.success) {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                self.refetchData();
              } else {
                toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: response.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 10000,
                  }
                );
              }
            })
            .catch((response) => {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: response.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                },
                {
                  timeout: 10000,
                }
              );
            });
        }
      });
    };

    const openAdd = () => {
      isAdd.value = true;
      isSidebarActive.value = true;
      isDisabled.value = false;
    };

    const parseConExp = (cronExp) => {
      return cronstrue.toString(cronExp, {
        locale: currentLocale.value,
        use24HourTimeFormat: true,
      });
    };

    return {
      // Sidebar
      isSidebarActive,
      isAdd,
      itemEdit,
      openEdit,
      openAdd,
      confirmDelete,
      update,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      scheduleTaskOptions,
      commandOptions,
      cameraOptions,
      frequencyScheduleTaskOptions,
      userViewsOptions,
      unitsOptions,
      unitsLoadingStatus,
      isDisabled,
      parseConExp,
    };
  },
};
</script>